export default {
  proxy_path: "https://b3d79yg32h.execute-api.us-west-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://rfiif5t9r6.execute-api.us-west-2.amazonaws.com/api",
  google_api_key: "AIzaSyB9O0BHfLu42XEe_50uDzubnJzPHFI0m6s",
  main_company_term_id: "9593",
  crm_base_url: "https://crm.evolution.forwoodsafety.com",
  forwood_id_url: "https://id.evolution.forwoodsafety.com?redirect_uri=https://mapreport.evolution.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.evolution.forwoodsafety.com",
  Auth: {
    userPoolId: "us-west-2_ivGzssITx",
    userPoolWebClientId: "28v1300qopcf3fs2u3892b6tci",
    cookieStorage: {
      domain: ".evolution.forwoodsafety.com",
      secure: true
    }
  }
};
